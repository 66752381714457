<template>
  <div>
    <v-card-title class="headline">Nutzungsvereinbarung:</v-card-title>
    <v-card-text>
      <p>
        <b
          >Bedingungen für die Abgabe und Verwendung des digitalen Datensatzes zum Teil Windenergie des Energieatlas NRW
          bei Nutzung durch Kommunen, Kreise und die Landes- und Regionalplanung in NRW</b
        >
      </p>
      <p>
        Das Landesamt für Natur, Umwelt und Verbraucherschutz Nordrhein-Westfalen stellt den zuständigen
        Planungsbehörden für die Planung und Ausweisung von Flächen für die Windenergie digitale Fachdaten aus
        verschiedenen Quellen unentgeltlich für die Zeit der Planung und Ausweisung der Flächen zur Verfügung. Hierfür
        gelten die nachstehenden Bedingungen:
      </p>
      <ol class="mb-5">
        <li>
          Die Nutzung der Daten wird nur den zuständigen Planungsbehörden und ausschließlich für die Planung und
          Ausweisung von Flächen für die Windenergie, wie z.B. Konzentrationsflächen oder Vorrangflächen, gestattet.
          Eine darüber hinausgehende Nutzung ist ohne erneute Genehmigung des entsprechenden Datenherausgebers (vgl.
          www.energieatlas.nrw.de) nicht gestattet. Nach Abschluss der Planung und Ausweisung sind die Daten inklusive
          aller Zwischenprodukte restlos unverzüglich zu löschen.
        </li>
        <li>
          Die Verwendungen der Daten zur Information der Öffentlichkeit ist mit Angabe des Herausgebers (Landesamt für
          Natur, Umwelt und Verbraucherschutz Nordrhein-Westfalen, LANUV NRW) und der entsprechenden Datenquelle (vgl.
          www.energieatlas.nrw.de) gestattet und kostenfrei.
        </li>
        <li>
          Die Nutzung der Daten zu kommerziellen Zwecken, d.h. zur Erzielung eines wirtschaftlichen Gewinns, erfordert
          den Abschluss einer Nutzungsvereinbarung mit dem Landesamt für Natur, Umwelt und Verbraucherschutz NRW bzw.
          dem entsprechenden Datenherausgeber (vgl. www.energieatlas.nrw.de) mit Geltung der Allgemeinen Geschäfts- und
          Nutzungsbedingungen und die Zahlung eines entsprechenden Nutzungsentgelts. Dies gilt insbesondere auch für die
          Weiterverwendung der Daten zu Zwecken des Aufbaus eines digitalen Datenbestands mit der Absicht der
          kommerziellen Nutzung oder die Veräußerung der Daten.
        </li>
        <li>
          Eine kommerzielle Weiterverwendung der Daten ohne eine entsprechende Vereinbarung stellt eine Verletzung der
          urheberrechtlich geschützten Nutzungsrechte des Datenherausgebers an diesen Daten dar und hat ggfs.
          Unterlassungs- und Schadensersatzansprüche zur Folge.
        </li>
        <li>
          Die Weitergabe der Daten an einen Auftragnehmer des Lizenznehmers ist zulässig, soweit und solange dies zur
          Planung und Ausweisung von Flächen für die Windenergie notwendig ist. Im Fall der Weitergabe von Daten an
          einen Auftragnehmer hat der Lizenznehmer diesen schriftlich zu verpflichten, die Daten ausschließlich für die
          Bearbeitung des Auftrages zu verwenden, dafür zu sorgen, dass diese in keinem Fall unberechtigten Dritten
          zugänglich gemacht werden sowie unverzüglich nach Erfüllung des Auftrages alle bei ihm verbliebenen Daten
          (inkl. Zwischenprodukte und Arbeitkopien) restlos zu löschen.
        </li>
        <li>
          Es ist dafür zu sorgen, dass unberechtigte Dritte, die an der Auftragserfüllung nicht beteiligt sind, keinen
          Zugriff auf die Daten nehmen können und die Daten weder für ihre eigenen Zwecke nutzen noch Dritten zugänglich
          machen können.
        </li>
        <li>Daten und Aussagen dürfen nicht verändert werden.</li>
        <li>
          Die Aktualität der Daten ist dem Energieatlas unter www.energieatlas.nrw.de zu entnehmen. Aktuellere Daten
          sind bei den entsprechenden Datenherausgebern einzuholen.
        </li>
        <li>
          Für die Fehlerfreiheit, die Richtigkeit und Vollständigkeit sowie die Verfügbarkeit der Daten und deren
          Übertragung gilt keine Gewähr. Für mittelbare oder unmittelbare Schäden, die durch die Nutzung der Daten oder
          eine mangelnde Verfügbarkeit entstehen wird keinerlei Haftung übernommen. Der Haftungsausschluss bezieht sich
          sowohl auf das LANUV als auch auf die entsprechenden Datenquellen und die jeweiligen Beschäftigten
        </li>
        <li>Urheberrechte werden nicht übertragen. Alle Daten sind urheberrechtlich geschützt.</li>
        <li>
          Der Auftragnehmer verpflichtet sich, dem Landesamt für Natur, Umwelt und Verbraucherschutz NRW auf Anfrage
          mitzuteilen, in welcher Weise die Daten genutzt werden.
        </li>
      </ol>
      <p>
        Sollten Sie die Planung von Flächen für die Windenergie an einen
        <b>externen Auftragnehmer</b> vergeben und die Daten dafür weitergeben wollen, so ist dies unter Beachtung der
        Nutzungsbedingungen möglich. Als Nutzungsvereinbarung zwischen Ihnen und dem Auftragnehmer nutzen Sie bitte
        folgendes Dokument
        <a :href="termsOfUse" target="_blank"
          >Bedingungen für die Abgabe und Verwendung des digitalen Datensatzes zum Teil Windenergie des Energieatlas NRW
          bei Nutzung durch Auftragnehmer von Kommunen und Kreisen in NRW</a
        >
        <b> und senden dieses im Anschluss in Kopie ausgefüllt an folgende Emailadresse: Fachbereich37@lanuv.nrw.de</b>.
      </p>
    </v-card-text>
  </div>
</template>
<script>
export default {
  computed: {
    termsOfUse() {
      return `./docs/Nutzungsbedingungen_Auftragnehmer.pdf`;
    }
  }
};
</script>
